import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { globalHistory } from "@reach/router";
import Scroll from "react-scroll";
import { BlockZoneFragment, MetaCardFragment } from "../GraphQl/queryFragments"; //eslint-disable-line
import { useAppContext } from "../state";
import SEO from "../components/global/seo";
import { BlockZone } from "../components/pageBlocks/blockZone";

const StandardPage = ({ data: { page } }) => {
  const { metaDescription, metaTitle, metaKeywords, metaImage } =
    page.meta || {};
  const { pageBlocks, reverseHeader } = page.main || {};
  const { setReverseHeader, setHiddenHeaderFooter } = useAppContext();

  const scroll = Scroll.animateScroll;
  const search = globalHistory.location.search;
  const target = parseInt(search.split("#")[1]);

  useEffect(() => {
    if (target) {
      scroll.scrollTo(target);
    }
    if (reverseHeader === true) {
      setReverseHeader(true);
    } else setReverseHeader(false);
    setHiddenHeaderFooter(false);
  }, [target, scroll, setReverseHeader, reverseHeader, setHiddenHeaderFooter]);

  return (
    <>
      <SEO
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        metaImage={metaImage}
      />
      {pageBlocks && <BlockZone zoneBlocks={pageBlocks} />}
    </>
  );
};

export default StandardPage;

export const standardPageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityStandardPage(main: { slug: { current: { eq: $slug } } }) {
      main {
        pageBlocks {
          ...BlockZoneFields
        }
        reverseHeader
      }
      meta {
        ...MetaCardFields
      }
    }
  }
`;
